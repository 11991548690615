import { useMutation, useQuery, useQueryClient } from "react-query";

import { MickApiClient } from "../MickApiClient";
import { InitialProfile, Profile } from "../schemas";

import { NewTransaction, UpdatedTransaction } from "./transactions";
import { TransactionFilters } from "./types";

const ACCOUNT_KEY = "ACCOUNT";

export function useAccount(
  enabled = true,
  refetchInterval: number | false = false,
  retryOnMount = true,
) {
  return useQuery(
    [ACCOUNT_KEY],
    () => MickApiClient.sharedInstance.getAccount(),
    { retry: 3, enabled, retryOnMount, refetchInterval },
  );
}

export function useAccountTwo() {
  return useQuery(
    // Useful to seed draft without accepting incoming refresh
    [ACCOUNT_KEY, "INITIAL_ACCOUNT"],
    () => MickApiClient.sharedInstance.getAccount(),
    { retry: 3, refetchOnWindowFocus: false },
  );
}

export function useUpdateProfile() {
  const queryClient = useQueryClient();
  return useMutation(
    [ACCOUNT_KEY, "update-profile"],
    async (
      params: Partial<Profile> & {
        event?: "immat_inpi_done" | "immat_cust_begin";
      },
    ) => MickApiClient.sharedInstance.updateProfile(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(ACCOUNT_KEY);
      },
    },
  );
}

export function useUpdateProfileAsAdmin(id: string) {
  const queryClient = useQueryClient();
  return useMutation(
    [ACCOUNT_KEY, "update-profile"],
    async (
      params: Partial<Profile> & {
        event?: "immat_inpi_done" | "immat_cust_begin";
      },
    ) => MickApiClient.sharedInstance.updateProfileAsAdmin(id, params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(ACCOUNT_KEY);
      },
    },
  );
}

export function useRegisterProduct() {
  const queryClient = useQueryClient();
  return useMutation(
    [ACCOUNT_KEY, "register-product"],
    async ({
      promoCode,
      promoCodeName,
      promoAmount,
      remainingAmount,
      registrationOk,
    }: {
      promoCode: string | false;
      promoAmount: number;
      promoCodeName?: string;
      remainingAmount: number;
      registrationOk: boolean;
    }) =>
      MickApiClient.sharedInstance.registerProduct(
        registrationOk,
        promoCode,
        promoAmount,
        remainingAmount,
        promoCodeName,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(ACCOUNT_KEY);
      },
    },
  );
}

export function useCreateProfile() {
  const queryClient = useQueryClient();
  return useMutation(
    [ACCOUNT_KEY, "cfate-profile"],
    async (params: InitialProfile) =>
      MickApiClient.sharedInstance.createProfile(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(ACCOUNT_KEY);
      },
    },
  );
}

export function useGetCheckoutUrl() {
  return useMutation(
    ["CHECKOUT", "PRODUCT_REGISTRATION"],
    async (promotionCodeId?: string) =>
      MickApiClient.sharedInstance.getCheckoutUrl(promotionCodeId),
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSuccess: async () => {},
    },
  );
}

export function useCheckCouponCode() {
  return useMutation(["CHECKOUT", "COUPON_CODE"], async (code: string) =>
    MickApiClient.sharedInstance.checkPromotionCode(code),
  );
}

const TRANSACTIONS_KEY = "TRANSACTIONS";

export function useTransactions(transactionsParams: TransactionFilters = {}) {
  return useQuery(
    [TRANSACTIONS_KEY, transactionsParams],
    () => MickApiClient.sharedInstance.getTransactions(transactionsParams),
    {
      retry: 3,
      keepPreviousData: true,
    },
  );
}

export function useCreateTransaction() {
  const queryClient = useQueryClient();
  return useMutation(
    [TRANSACTIONS_KEY],
    async (newTransaction: NewTransaction) => {
      console.log("creating transaction", newTransaction);
      await MickApiClient.sharedInstance.createTransaction(newTransaction);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(TRANSACTIONS_KEY);
      },
    },
  );
}

export function useUpdateTransaction() {
  const queryClient = useQueryClient();
  return useMutation(
    [TRANSACTIONS_KEY, "update"],
    async (updatedTransaction: UpdatedTransaction) => {
      console.log("updating transaction", updatedTransaction);
      await MickApiClient.sharedInstance.updateTransaction(updatedTransaction);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(TRANSACTIONS_KEY);
      },
    },
  );
}

export function useDeleteTransaction() {
  const queryClient = useQueryClient();
  return useMutation(
    [TRANSACTIONS_KEY, "delete"],
    async (transactionId: string) => {
      await MickApiClient.sharedInstance.deleteTransaction(transactionId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(TRANSACTIONS_KEY);
      },
    },
  );
}

export const FILE_QUERY_KEY = "files";

export function useFiles() {
  return useQuery(
    [FILE_QUERY_KEY, "all"],
    () => MickApiClient.sharedInstance.getFiles(),
    { retry: 3 },
  );
}

export function useDeleteFile() {
  const queryClient = useQueryClient();
  return useMutation(
    [TRANSACTIONS_KEY, "delete"],
    async (fileId: string) => {
      await MickApiClient.sharedInstance.deleteFile(fileId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(FILE_QUERY_KEY);
      },
    },
  );
}
